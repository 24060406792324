<template>
<div class="custom_tab">
            <div class="tab_content">
              <slot></slot>
            </div>
            <div class="tabs">
                <div v-for="category in categories" 
                :key="category.replace(' ','_')" 
                @click="selectTab(category.replace(' ','_'))" 
                :class="[selected===category.replace(' ','_')?'selected_tab':'']" 
                class="tab_item">{{category}}</div>
            </div>
          </div>
</template>    
<script>
import { defineComponent } from "vue";
export default defineComponent({
 props:['categories'],
  data(){
    return {
        selected:''
    }
  },
  components: {},
  mounted(){
    this.selected = this.categories[0].replace(' ','_')
    this.selectTab(this.selected)
  },
  methods:{
    selectTab(tabName){
        this.selected = tabName;
        this.$emit('select-change',tabName)
    }
  }
  })
</script>
<style scoped>
.custom_tab{
  width: 60%;
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  justify-items: space-between;
}
.selected_tab{
    background-color: grey;
    color: white;
}
.tabs {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 50%;
}

.tab_item{
    width: 100%;
    padding: 5px;
    text-overflow: ellipsis;
    
    cursor: pointer;
    user-select: none;
}

.tab_content{
    border-right: solid 1px rgba(128, 128, 128, 0.555);
    width: 70%;
    padding: 10px;
    margin: 0px;
    height: 100vh;
}
</style>