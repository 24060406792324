<template>
    <div v-for="item in devices" v-bind:key="item.device_id">
        <ion-card>
          <ion-row>
            <ion-col size="auto">
              <ion-card-subtitle
              >Device Name:
                <b>{{ item.device_alias }}</b></ion-card-subtitle
              >
              <div
                  v-if="
                            item.device_feed &&
                            item.device_feed.holding_registers
                          "
              >
                <ion-card-subtitle
                >Last Published At:
                  <b>{{
                      item.device_feed.publishedAt
                    }}</b></ion-card-subtitle
                >
                <div
                    v-for="item in item.device_feed.holding_registers"
                    v-bind:key="item.alias"
                >
                  <ion-card-subtitle
                      float-right
                      v-if="item.alias === 'Key Position'"
                  >
                              <span v-if="item.value === 'Key in Auto'"
                              >{{ item.alias }} <ion-badge class="color-full-green"
                                >O</ion-badge
                                >:
                                <b class="color-text-green">{{ item.value }}</b>
                                </span
                              >
                    <span v-else-if="item.value === 'Not Available'"
                    >{{ item.alias }} <ion-badge class="color-full-black"
                                >O</ion-badge
                                >:
                                <b class="color-text-black">{{ item.value }}</b>
                                </span
                    >
                    <span v-else
                    >{{ item.alias }} <ion-badge class="color-full-red"
                                >O</ion-badge
                                >:
                                <b class="color-text-red">{{ item.value }}</b>
                                </span
                    >
                  </ion-card-subtitle>

                  <ion-card-subtitle
                      float-right
                      v-else-if="item.alias === 'Amber Warning Lamp'"
                  >
                              <span
                                  v-if="
                                  item.value === 'On, Solid' ||
                                  item.value === 'On, Flashing'
                                "
                              >{{ item.alias }} <ion-badge class="color-full-amber"
                                >O</ion-badge
                                >:
                                <b class="color-text-amber">{{ item.value }}</b>
                                </span
                              >
                    <span v-else
                    >{{ item.alias }} <ion-badge class="color-full-black"
                                >O</ion-badge
                                >:
                                <b class="color-text-black">{{ item.value }}</b>
                                </span
                    >
                  </ion-card-subtitle>

                  <ion-card-subtitle
                      float-right
                      v-else-if="item.alias === 'Red Stop Lamp'"
                  >
                              <span
                                  v-if="
                                  item.value === 'On, Solid' ||
                                  item.value === 'On, Flashing'
                                "
                              >{{ item.alias }} <ion-badge class="color-full-red"
                                >O</ion-badge
                                > :
                                <b class="color-text-red">{{ item.value }}</b>
                                </span
                              >
                    <span v-else
                    >{{ item.alias }} <ion-badge class="color-full-red"
                                >O</ion-badge
                                > :
                                <b class="color-text-black">{{ item.value }}</b>
                                </span
                    >
                  </ion-card-subtitle>

                  <ion-card-subtitle v-else float-right
                  >{{ item.alias }}: <b>{{ item.value }}</b>
                    <span v-if="item.value != 'Not Available'">{{
                        item.unit
                      }}</span>
                  </ion-card-subtitle>
                </div>
                <ion-button
                    v-if="canControl"
                    @click="$emit('openControlModal',item)"
                    size="small"
                    shape="round"
                >Control</ion-button
                >
              </div>
              <div v-else>Device Status: Offline</div>
            </ion-col>
          </ion-row>
        </ion-card>
      </div>
</template>
<script>
    import {
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardSubtitle,
  IonBadge,
} from "@ionic/vue";
export default {
    props:['devices','canControl'],
    components:{
        IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardSubtitle,
  IonBadge,
    }
}
</script>

<style scoped>

</style>